.layout {
  display: grid;

  main {
    /* padding-top: 84px; */ /* Height of the Navbar */
  }

  @media only screen and (max-width: 768px) {
    main {
      /* padding-top: 60px; */
    }
  }
}
