.mainsl_container {
  padding: 10px 16px;
  position: relative;
  max-height: 56px;

  display: grid;
  grid-template-areas: "name slider btn";
  grid-template-columns: max-content auto max-content;

  .btn_group {
    grid-area: btn;
    display: grid;
    grid-template-areas: "decrement line value line2 increment";
    grid-template-rows: 36px;
    place-items: center;

    margin-left: 32px;
    border-radius: 4px;
    /* background-color: var(--clr-lime-8); */
    background: linear-gradient(0deg, #e03131, #fab005, var(--clr-lime-8));
    background-size: 100000% 100000%;
    color: #fff;
    font-size: 20px;
    font-weight: bold;

    p {
      display: grid;
      place-items: center;
      grid-area: value;
      margin: 0;
      width: 70px;
    }
    .line_one {
      grid-area: line;
    }
    .line_two {
      grid-area: line2;
    }
    .line {
      height: 70%;
      width: 2px;
      background-color: #fff;
    }
    .increment {
      grid-area: increment;
    }
    .decrement {
      grid-area: decrement;
    }
    button {
      width: 35px;
    }
    button:disabled,
    button[disabled] {
      cursor: unset;
      img {
        opacity: 0.3;
      }
    }
  }

  &:last-child {
    margin-bottom: 12px;
  }

  label {
    grid-area: name;
    font-weight: bold;
    width: 165px;
    margin: 0 16px 0 0;
    /* display: flex; */
    /* align-items: center; */

    line-height: 36px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .slider_container {
    grid-area: slider;
    display: grid;
    place-items: center;
    width: 100%;
  }

  // slider things
  .slider_minLabel {
    left: -6ch;
    text-align: right;
    width: 50px;
  }
  .slider_maxLabel {
    right: -6ch;
    text-align: left;
    width: 50px;
  }
  .slider_minLabel,
  .slider_maxLabel {
    display: none;

    top: -1px;
    font-family: Nunito;
    font-size: 16px;
    color: #212529;
    font-weight: 600;
  }
  .slider_label {
    display: none;

    font-size: 16px;
    font-weight: 600;
    color: #212529;
    position: absolute;
    font-family: "Nunito";
    top: 24px;
  }
  .slider_track {
    height: 12px;
    background-color: var(--clr-cyan-3);
    border-radius: 8px;
  }
  .slider_slider {
    background-color: var(--clr-gray-5);
    border: 1px solid var(--clr-gray-6);
    height: 28px;
    width: 28px;
    margin-top: -20px;
    z-index: 1;

    background-color: none;
    border: none;
    border-radius: 0;
    height: 35px;
    width: 50px;
    margin-top: -22px;
    margin-left: -28px;

    background: url("../assets/icons/Sliderbutton.svg");
    background-position: -50% -50%;
    background-repeat: no-repeat;
  }
  .slider_inputRange {
    margin: 0 auto;
  }
  .slider_valueLabel {
    display: none;

    background-color: #fff;
    position: relative;
    font-size: 18px;
    z-index: 91;
    top: -49px;
    left: -50%;
  }
  .slider_labelContainer {
    display: none;

    left: 0;
  }
}

@media screen and (max-width: 680px) {
  .mainsl_container {
    max-height: unset;
    grid-template-areas:
      "name   name"
      "slider btn";
    grid-template-columns: auto min-content;

    .btn_group {
      font-size: 16px;
      margin-left: 8px;

      p {
        width: 50px;
      }

      button {
        width: 24px;
        img {
          width: 12px;
        }
      }
    }
  }
}
