.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;

  .modalContent {
    position: relative;
    z-index: 99;
    background-color: var(--clr-gray-1);
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    max-height: 800px;

    overflow-y: scroll;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    padding: 32px;
    border-radius: 4px;
    text-align: center;
    h1 {
      color: var(--accent);
      font-size: 32px;
      margin: 0 0 16px 0;
    }
  }
}

.close {
  position: absolute;
  top: -20px;
  right: 0;
  padding: 16px;
  color: var(--dark-text-clr-0);
  float: right;
  font-size: 38px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: var(--dark-text-clr-0);
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal {
    .modalContent {
      width: 100%;
      padding: 4px;
    }
  }
}

@media screen and (max-height: 800px) {
  .modal {
    .modalContent {
      max-height: 100%;
    }
  }
}
