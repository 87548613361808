.wizardconf {
  height: 100%;
  padding-top: 64px;
  scroll-snap-align: center;
  position: relative;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }

  .cat_title {
    margin-bottom: 24px;
    text-align: center;
    background-color: var(--clr-cyan-4);

    h4 {
      font-size: 32px;
      font-weight: bold;
      padding: 8px 0;
    }
  }

  .wizardherb {
    width: 420px;
    display: grid;
    grid-template-areas:
      ". switch ."
      ".  img   .";
    grid-template-columns: 20px auto 20px;
    grid-template-rows: auto auto;
    row-gap: 12px;
    column-gap: 8px;
    place-items: center;

    .videoBtn {
      grid-area: video;
      margin: 0 0 0 auto;
    }

    .imgBackground {
      grid-area: img;
      width: 100%;

      background-color: rgb(160, 160, 160);
      display: block;
      margin: 0 auto;
      position: relative;

      img {
        aspect-ratio: 4 / 3;
        object-fit: fill;
        width: 100%;
      }

      .play_btn {
        aspect-ratio: 16 / 9;
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 64px;
        margin: 0 4px 8px 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .switch {
      grid-area: switch;
      position: relative;
      display: inline-block;
      width: 100%;
      height: 32px;
      margin: 0 auto 0 0;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      .display_title {
        font-size: 22px;
        margin-top: 2px;
        margin-left: 32px;
        max-height: 32px;
        position: absolute;
        z-index: 99;

        /* overflow: hidden; */
      }

      span {
        // custom checkbox
        position: absolute;
        cursor: pointer;
        height: 23px;
        width: 23px;
        border-radius: 2px;
        border: 1px solid #000;
        z-index: 2;

        top: 50%;
        left: 12px;
        transform: translate(-50%, -50%);

        &::after {
          content: "";
          position: absolute;
          display: none;

          left: 12px;
          top: -8px;
          width: 10px;
          height: 24px;
          border: solid rgb(0, 0, 0);
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      input {
        // default checkbox hidden
        position: absolute;
        cursor: pointer;
        opacity: 0;
        height: 0;
        width: 0;
      }
      input:checked ~ span {
        background-color: var(--clr-lime-8);
      }
      input:checked ~ span::after {
        display: block;
      }
    }
  }

  .nextbtn {
    height: max-content;
    position: relative;
    h4 {
      position: relative;
      z-index: 99;
      margin-top: 32px;
    }
    button {
      display: block;
      margin: 0 auto;
      width: 120px;
      height: 100%;
      img {
        max-height: 34px;
        max-width: 34px;
        transform: rotate(180deg) scale(1.3);
        content: url("../../assets/icons/tribtn.svg");
      }
    }
  }

  .prevbtn {
    margin-bottom: 48px;
    height: max-content;
    position: relative;
    h4 {
      z-index: 99;
    }
    button {
      display: block;
      margin: 0 auto;
      width: 120px;
      height: 100%;
      img {
        max-height: 34px;
        max-width: 34px;
        transform: scale(1.3);
        content: url("../../assets/icons/tribtn.svg");
      }
    }
  }

  .steptwo {
    font-size: 18px;
    color: #000;

    display: grid;
    grid-template-areas:
      "."
      ".";
    place-items: center;
    text-decoration: none;

    margin: 32px auto 0 auto;
    width: 120px;
    height: 100%;

    img {
      max-height: 34px;
      max-width: 34px;
      transform: rotate(180deg) scale(1.3);
      content: url("../../assets/icons/tribtn.svg");
    }
  }
}

@media screen and (orientation: landscape) and (min-device-width: 200px) and (max-device-width: 1000px) {
  .wizardconf {
    height: calc(100% + 60px);

    .cat_title {
      margin-bottom: 8px;
  
      h4 {
        font-size: 18px;
      }
    }

    .wizardherb {
      max-height: 90px;
      grid-template-columns: 0 auto 0;
      position: relative;
      z-index: 1000;

      .imgBackground {
        grid-area: img;
        width: 4.5em;
        position: relative;
        z-index: 98;

        img {
          aspect-ratio: 4 / 3;
          object-fit: fill;
          width: 100%;
        }

        .play_btn {
          aspect-ratio: 16 / 9;
          position: absolute;
          bottom: 0;
          right: 0;
          max-width: 64px;
          margin: 0 4px 8px 0;

          &:hover {
            cursor: pointer;
          }
        }
      }
      .switch {
        height: 24px;

        .display_title {
          font-size: 18px;
        }
      }
    }

    .nextbtn {
      h4 {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .prevbtn {
      h4 {
        font-size: 16px;
      }
    }

    .steptwo {
      h4 {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-height: 651px) {
  .wizardconf {
    padding-top: 0;

    .cat_title {
      padding-bottom: 4px;
      h4 {
        font-size: 24px;
      }
    }

    .wizardherb {
      width: 240px;
    }

    .nextbtn {
      margin: 8px 0;
    }
    .prevbtn {
      margin: 8px 0;
    }
  }
}

@media screen and (max-width: 950px) {
  .wizardconf {
    .cat_title {
      h4 {
        font-size: 22px;
        padding: 2px 0 0 0;
        margin: 0;
      }
    }

    .wizardherb {
      width: 240px;
      grid-template-columns: 0 auto 0;

      switch {
        .display_title {
          font-size: 18px;
        }
      }

      .imgBackground {
        .play_btn {
          aspect-ratio: 16 / 9;
          max-width: 42px;
        }
      }
    }

    .nextbtn {
      margin: 12px 0;
      height: 20px;
      button {
        img {
          transform: rotate(180deg) scale(1);
        }
      }
    }

    .prevbtn {
      margin: 12px 0;
      height: 20px;
      button {
        img {
          transform: scale(1);
        }
      }
    }

    .steptwo {
      img {
        transform: rotate(180deg) scale(1);
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .wizardconf {
    padding-top: 32px;
    .cat_title {
      h4 {
        font-size: 20px;
        margin: 0;
        padding: 2px 0 0 0;
      }
    }
    .wizardherb {
      width: 260px;

      .switch {
        .display_title {
          margin-top: 4px;
          font-size: 20px;
        }
      }
    }

    @supports (-webkit-touch-callout: none) {
      padding-top: 0;
      .wizardherb {
        width: 230px;

        .switch {
          .display_title {
            margin-top: 4px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
