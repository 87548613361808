.two_column {
  .banner {
    display: grid;
    place-items: center;

    height: 372px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 55%;

    h1 {
      font-size: 56px;
      color: #fff;
      font-weight: bold;
      text-align: left;
      width: 100%;
    }
  }

  .content {
    margin-top: 64px;

    blockquote {
      font-size: 22px;
      line-height: 32px;
      max-width: 1000px;
      margin: 0 auto;
      margin-bottom: 64px;

      p {
        display: grid;
        grid-template-areas:
          "img title"
          "img text";
        column-gap: 2em;
        grid-template-columns: 55% auto;

        strong {
          grid-area: title;
          margin: auto 0 0 0;
          margin-bottom: 8px;
        }
        img {
          grid-area: img;
        }
      }
    }

    strong {
      font-size: 30px;
      line-height: 40px;
    }

    img {
      max-width: 100%;
    }

    blockquote:nth-of-type(even) {
      p {
        grid-template-areas:
          "title img"
          "text img";
        grid-template-columns: auto 55%;

        img {
          margin: auto 0 auto auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .two_column {
    .content {
      margin-top: 32px;
      blockquote {
        p {
          grid-template-areas:
            "img"
            "title"
            "text";
          grid-template-columns: auto;

          max-width: 490px;
          margin: 0 auto;
        }
      }

      img {
        margin-bottom: 16px;
      }

      blockquote:nth-of-type(even) {
        p {
          grid-template-areas:
            "img"
            "title"
            "text";
          grid-template-columns: auto;

          img {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .two_column {
    .banner {
      h1 {
        font-size: 3rem;
        text-align: center;
      }
    }
    .content {
      blockquote {
        font-size: 18px;
      }

      strong {
        font-size: 24px;
      }
    }
  }
}
