.title {
  color: var(--light-text-clr-0);
  font-size: 52px;
  font-weight: 800;
  padding: 64px 20px;
  text-align: center;
}

.kassa_container {
  display: grid;
  grid-template-areas: "order summary";
  .order_info {
    grid-area: order;
    max-width: 760px;
    margin-top: 64px;
    margin-bottom: 156px;

    h1 {
      font-size: 20px;
      font-weight: normal;
    }

    .form_c {
      display: grid;
      grid-template-areas:
        "fnamn fnamn"
        "lnamn lnamn"
        "personnummer personnummer"
        "adress adress"
        "postnummer postnummer"
        "postort postort"
        "epost epost"
        "mobilnummer mobilnummer"
        "ordercomment ordercomment"
        "consultation consultation"
        "terms terms"
        ".btn";
      column-gap: 16px;

      .epost {
        grid-area: epost;
      }
      .mobilnummer {
        grid-area: mobilnummer;
      }
      .personnummer {
        grid-area: personnummer;
        input {
          background-color: #eeeeee;
          opacity: 0.8;
          &:focus {
            outline: none;
          }
        }
      }
      .fnamn {
        grid-area: fnamn;
      }
      .lnamn {
        grid-area: lnamn;
      }
      .adress {
        grid-area: adress;
      }
      .postnummer {
        grid-area: postnummer;
      }
      .postort {
        grid-area: postort;
      }
      .ordercomment {
        grid-area: ordercomment;
      }
      .consultation {
        grid-area: consultation;
        label {
          font-size: 18px;
          display: flex;
          place-items: center;
        }
      }
      .terms {
        grid-area: terms;
        label {
          font-size: 18px;
          display: flex;
          place-items: center;

          a {
            margin-left: 8px;
          }
        }
      }

      input,
      textarea {
        width: 100%;
        border: 1px solid #666666;
        border-radius: 4px;
        height: 48px;
        font-size: 18px;
        text-indent: 16px;
      }
      textarea {
        padding: 8px 16px;
        text-indent: 0;
        height: auto;
      }

      input[type="checkbox"],
      [type="radio"] {
        height: 26px;
        width: 26px;
        cursor: pointer;
        margin-right: 12px;
      }

      .error {
        color: var(--clr-error);
        font-size: 14px;
        width: max-content;
      }
    }

    p {
      margin-top: 32px;
      font-size: 16px;
    }

    .buyBtn {
      grid-area: btn;
      font-weight: 600;
      font-size: 20px;
      height: 59px;
      color: var(--light-text-clr-0);
      display: block;
      background-color: var(--accent);
      font-size: 18px;
      padding: 12px 48px;
      margin: 8px 0 0 auto;
      border-radius: 4px;

      &:hover {
        background-color: var(--accent-darker);
      }
    }
    .buyBtn:disabled,
    .buyBtn[disabled] {
      background-color: #616161;
      cursor: default;
    }

    img {
      max-width: 100%;
      margin-top: 64px;
    }
  }

  .sammanstallning {
    grid-area: summary;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 64px;
    height: max-content;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: var(--clr-cyan-0);
    padding-bottom: 64px;

    h2 {
      border-radius: 4px 4px 0 0;
      padding: 16px 32px;
      background-color: var(--clr-cyan-5);
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }
    li {
      padding: 0 32px;
      list-style: none;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
        font-size: 18px;
      }
      .bold {
        font-weight: bold;
      }
      .big {
        font-size: 22px;
      }
      .small {
        font-size: 14px;
      }
    }
    .total {
      margin-top: 16px;
    }
    .frakt {
      margin-top: 8px;
    }
    .sum {
      margin-top: 16px;
    }
    .moms {
      margin-top: 0;
    }

    .separate {
      width: 95%;
      height: 2px;
      background-color: var(--clr-gray-5);
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1000px) {
  .kassa_container {
    grid-template-areas:
      "summary"
      "order";
  }
}
