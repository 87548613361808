.herblist {
  display: grid;
  grid-template-areas: "category prv list nxt";
  grid-template-columns: 18ch min-content auto min-content;
  width: 100%;

  .title {
    grid-area: category;
    margin: 0;
    display: grid;
    place-items: center start;
    overflow: hidden;
    font-weight: 700;
  }

  .prv {
    grid-area: prv;
    padding: 0 8px;
  }

  .nxt {
    grid-area: nxt;
    padding: 0 8px;

    img {
      top: -2px;
    }
  }

  ul {
    list-style: none;
    margin: 0;

    grid-area: list;
    display: grid;
    grid-template-columns: repeat(4, minmax(180px, 200px));

    li {
      display: grid;
      margin: 8px;
      position: relative;
      padding-left: 35px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-align: left;
      width: 100%;
      place-items: center start;

      label {
        cursor: pointer;
        margin-bottom: 0;
        color: var(--clr-grey-8);
        font-weight: 700;
      }

      input {
        // default checkbox hidden
        position: absolute;
        cursor: pointer;
        opacity: 0;
        height: 0;
        width: 0;
      }

      span {
        // custom checkbox
        position: absolute;
        cursor: pointer;
        top: 2px;
        left: 0;
        height: 23px;
        width: 23px;
        border-radius: 2px;
        background-color: var(--clr-cyan-2);
        z-index: 2;

        top: 50%;
        left: 17px;
        transform: translate(-50%, -50%);

        &::after {
          content: "";
          position: absolute;
          display: none;

          left: 8px;
          top: 4px;
          width: 7px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &:hover {
          background-color: var(--clr-cyan-3);
        }
      }

      input:checked ~ span {
        background-color: var(--clr-cyan-4);
      }
      input:disabled ~ span {
        background-color: var(--clr-cyan-4);
      }
      input:checked ~ span::after {
        display: block;
      }
    }
  }
}

hr {
  border: 1px solid var(--clr-gray-6);
  margin: 4px -16px;

  &:last-child {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .herblist {
    ul {
      grid-template-columns: repeat(3, minmax(180px, 200px));
    }
  }
}

@media screen and (max-width: 1150px) {
  .herblist {
    ul {
      grid-template-columns: repeat(2, minmax(180px, 200px));
    }
  }
}

@media screen and (max-width: 750px) {
  .herblist {
    grid-template-areas:
      "category category category"
      "prv list nxt";
    grid-template-columns: min-content auto min-content;
    ul {
      grid-template-columns: repeat(2, minmax(180px, 200px));
    }
  }
}

@media screen and (max-width: 505px) {
  .herblist {
    ul {
      padding: 4px;
      grid-template-columns: minmax(180px, auto);

      li {
        max-width: 100%;
      }
    }
  }
}
