.login {
  .modal {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);

    .modalContent {
      background-color: var(--clr-gray-1);
      margin: auto;
      padding: 32px;
      border: 1px solid #888;
      border-radius: 4px;
      width: 80%;
      max-width: max-content;
      min-width: 460px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      display: grid;
      grid-template-areas:
        "close"
        "form";
      column-gap: 8px;
      grid-template-rows: max-content max-content;

      .form_section {
        margin: 8px auto 24px auto;
        width: 100%;

        img {
          height: 160px;
          margin: 0 auto;
          display: block;
        }

        h1 {
          font-size: 28px;
          margin: 0;
        }

        span {
          font-size: 14px;
          font-weight: 600;
        }

        .loginBtn {
          font-size: 18px;
          color: #fff;
          padding: 8px;
          background-color: #e8590c;
          width: 100%;
          font-weight: 600;
          border-radius: 4px;
          margin-top: 16px;

          img {
            display: inline-block;
          }
        }
      }

      .close {
        grid-area: close;
        color: var(--light-text-clr-1);
        margin: 0 0 0 auto;
        font-size: 28px;
        font-weight: bold;
        max-width: 40px;
      }

      .close:hover,
      .close:focus {
        color: var(--dark-text-clr-0);
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
