.overview_container {
  max-width: 100%;
  /* min-height: 500px; */
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  height: 100%;

  display: grid;
  grid-template-areas:
    "title title"
    "summary summary"
    "herbh herbh"
    "list list"
    "contact contact"
    "cont cont"
    "xtra xtra";
  grid-template-rows: max-content max-content max-content auto max-content max-content max-content;

  .oheader {
    background-color: var(--clr-cyan-5);
    padding: 4px 0;
    border-radius: 4px 4px 0 0;
    grid-area: title;
    h4 {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      padding: 12px;
      margin: 0;
    }
  }

  ul {
    padding: 8px;
    margin: 0;
    list-style: none;
    overflow: hidden;

    li {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }

    .separator {
      height: 1px;
      width: 100%;
      padding: 1px 0;
      background-color: var(--clr-gray-5);
    }

    .graybg {
      background-color: var(--clr-gray-1);
    }
  }

  .summaryList {
    grid-area: summary;
    .topo {
      display: flex;
      font-weight: bold;
    }
    .bottls {
      display: flex;
    }
  }

  .herb_heading {
    grid-area: herbh;
    background-color: var(--clr-cyan-2);
    color: var(--clr-gray-7);
    font-weight: bold;
    padding: 2px 8px;
    margin: 8px 0 0 0;
  }

  .list {
    grid-area: list;
  }

  .contactbtn {
    grid-area: contact;
    font-size: 18px;
    cursor: pointer;
    margin: 16px 8px 16px 8px;
    color: var(--dark-text-clr-0);
    background-color: var(--clr-cyan-0);
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    span {
      position: absolute;
      top: 7px;
      left: 6px;
      height: 25px;
      width: 25px;
      background-color: var(--clr-cyan-2);
      border-radius: 4px;

      &::after {
        content: "";
        position: absolute;
        display: none;
      }

      &:hover {
        background-color: var(--clr-cyan-3);
      }
    }
    input:checked ~ span {
      background-color: var(--clr-cyan-2);
    }
    input:disabled ~ span {
      background-color: #666;
    }
    input:checked ~ span::after {
      display: block;
    }

    :after {
      left: 9px;
      top: 4px;
      width: 7px;
      height: 14px;
      border: solid var(--clr-cyan-4);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    p {
      margin: 4px 0 0 36px;
      font-size: 14px;
      padding: 0;
    }
  }

  .btngroup {
    grid-area: cont;
    display: grid;
    place-items: center;
    grid-template-columns: 85% auto;
    column-gap: 4px;
    padding: 0 8px 8px 8px;

    .cont {
      max-height: 38px;
      max-width: 310px;
      padding: 4px 0;
      margin: 8px auto;
      width: 100%;
      background-color: #e8590c;
      color: #fff;
      border-radius: 4px;
      font-size: 20px;
      font-weight: bold;

      display: grid;
      place-items: center;

      &:disabled,
      &[disabled] {
        background-color: rgb(125, 125, 125);

        &:hover {
          cursor: default;
        }
      }
    }

    .cart_icon {
      border: 2px solid #9f9f9f;
      border-radius: 4px;
      padding: 4px 6px;
      height: max-content;
      position: relative;

      span {
        position: absolute;
        background-color: #e4572e;
        border-radius: 50%;
        padding: 0 3px;
        color: white;
        font-weight: bold;

        display: grid;
        place-items: center;

        position: absolute;
        top: -12px;
        right: -12px;

        p {
          margin: 0;
          width: 2ch;
          display: grid;
          place-items: center;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .xtrabtn {
    grid-area: xtra;
    display: grid;
    place-items: center;
    margin-bottom: 16px;
    button {
      border-radius: 4px;
      border: 1px solid #15aabf;
      background-color: var(--clr-cyan-0);
      font-weight: bold;
      font-size: 16px;
      margin: 16px 0 0 0;
      padding: 4px 0;
      width: 90%;
    }
  }
}
