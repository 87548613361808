.next_s {
  max-height: 64px;
  max-width: 64px;
  transform: rotate(90deg);
  content: url("../../assets/icons/tribtn.svg");
}
.prev_s {
  max-height: 64px;
  max-width: 64px;
  transform: rotate(-90deg);
  content: url("../../assets/icons/tribtn.svg");
}

@media screen and (max-width: 950px) {
  .next_s {
    display: none;
  }
  .prev_s {
    display: none;
  }
}
