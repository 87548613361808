.video_card {
  min-width: 300px;
  max-width: 340px;
  height: 200px;

  background-color: #666;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  transition: all 100ms ease;

  .overlay {
    height: 100%;
    width: 100%;
    padding: 8px 12px;
    background: linear-gradient(
      360deg,
      #000 0.5%,
      rgba(102, 217, 232, 0.1) 50%
    );

    display: flex;
    position: relative;

    .hover {
      display: none;
      position: absolute;
      left: 50%;
      top: 44%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      color: #ffc078;
      font-weight: bold;
      max-width: 260px;
      overflow: hidden;
    }

    h3 {
      margin-top: auto;
      margin-bottom: 0;
      color: #fff;
      font-weight: bold;
      font-size: 24px;
    }
  }

  &:hover {
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    .overlay2 {
      box-shadow: inset 0px 0px 0px 100px rgba(29, 29, 29, 0.7);
      .hover {
        display: block;
      }
    }
  }
}
