nav {
  background-color: white;
  z-index: 9;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 84px;

    .logo {
      vertical-align: middle;
      /* max-height: 40px; */
      max-height: 70px;
      cursor: pointer;
    }

    ul {
      list-style: none;
      margin: 0 auto 0 32px;
      padding: 0;

      .selected {
        li {
          &:after {
            width: 60%;
          }
        }
      }

      li {
        display: inline-block;
        color: var(--dark-text-clr-1);
        font-size: 16px;
        cursor: pointer;
        padding: 16px;
        margin: 0 4px;
        border-radius: 4px;
        position: relative;

        &:after {
          position: absolute;
          bottom: 6px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          content: "";
          background: var(--accent);
          height: 4px;
        }

        &:hover:after {
          width: 60%;
        }
      }

      .mobileLogin {
        display: none;
      }

      .dropdown {
        position: relative;
        display: inline-block;

        &:hover {
          .dropdown-content {
            display: grid;
          }
        }
      }

      .dropdown_content {
        display: none;

        position: absolute;
        top: 64px;
        left: 160px;

        width: max-content;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        border: 1px solid red;
      }
    }

    ul,
    ul li,
    ul li:after {
      transition: all 0.5s;
    }

    .button {
      padding: 10px 8px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      background-color: var(--accent);
      width: 150px;
      text-align: center;
      border-radius: 4px;
    }

    .mobileMenu {
      display: none;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      .bar {
        height: 4px;
        width: 36px;
        margin: 4px 0;
        border-radius: 2px;
        background-color: var(--dark-text-clr-0);

        &:first-child {
          width: 60%;
        }
      }
    }
  }

  @media screen and (max-height: 500px) {
    .container {
      min-height: 60px;
      ul {
        li {
          padding: 12px;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .container {
      display: grid;
      grid-template-areas:
        "logo . menu"
        "ul ul ul";
      grid-template-rows: 60px auto;
      min-height: 60px;

      .logo {
        grid-area: logo;
        max-height: 48px;
      }

      ul {
        grid-area: ul;
        display: none;
        margin: 0;

        .selected {
          li:after {
            width: 20%;
          }
        }

        li {
          display: block;
          text-align: center;
          padding: 20px;
          width: 100%;
          margin: 8px auto;
          font-weight: 600;

          &:hover:after {
            display: none;
          }
        }

        .mobileLogin {
          display: block;
          margin: 0 auto;
          margin-bottom: 16px;
          text-align: center;
        }
      }

      .login {
        display: none;
      }

      .mobileMenu {
        grid-area: menu;
        display: block;
        float: right;
      }

      .active {
        display: block;
      }
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 200px) and (max-device-width: 1000px) {
    min-height: 30px;
    .container {
      grid-template-rows: 30px auto;
      min-height: 30px;


      .logo {
        height: 30px;
      }
    }
  }
}
