.container {
  .banner {
    display: grid;
    place-items: center;

    height: 372px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 55%;

    h1 {
      font-size: 56px;
      color: #fff;
      font-weight: bold;
      text-align: left;
      width: 100%;
    }
  }

  .content {
    padding: 64px 20px;
    margin: 0 auto;
    max-width: 725px;

    p {
      font-size: 18px;
    }

    img {
      max-width: 100%;
    }

    /* a {
      padding: 8px 16px;
      text-decoration: none;
      color: #fff;
      background-color: #007a33;
      border-radius: 8px;

      &:hover {
        background-color: #00943e;
      }
    } */

    ul {
      margin-left: 20px;
    }
    /* li {
      list-style: none;
    } */
  }

  .ginskolan_faq_extra {
    h1,
    h2,
    h3,
    h4,
    p {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      margin-top: 24px;
    }

    blockquote {
      max-width: 1200px;
      p {
        max-width: 1200px;
      }
    }
  }

  .media_extra {
    margin-bottom: 128px;

    h1,
    h2,
    h3,
    h4,
    p {
      margin: 0;
    }

    h1 {
      font-size: 56px;
      color: #000;
      margin-top: 64px;
    }
    p {
      font-size: 24px;
      margin-left: 4px;
    }
    .category_title {
      margin-left: 25px;
      margin-top: 44px;
      font-weight: bold;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 705px) {
    .banner {
      h1 {
        font-size: 3rem;
        text-align: center;
      }
    }
    .media_extra {
      h1 {
        font-size: 42px;
      }
    }
  }
}

.shoppenbanner {
  display: grid;
  place-items: center;

  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 55%;

  h1 {
    font-size: 56px;
    color: #fff;
    font-weight: bold;
    text-align: left;
    width: 100%;
  }
}

.ginshoppen {
  max-width: 1440px;
  margin: 32px auto 0 auto;

  .ginconf {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    padding-bottom: 32px;

    .title {
      background-color: var(--clr-cyan-7);
      height: 120px;
      h1 {
        padding: 0 40px;
        color: #fff;
        font-weight: bold;
        line-height: 120px;
      }
    }

    .cont {
      padding: 0 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8em;

      .btn {
        width: 100%;
        height: 300px;
        padding: 40px;
        color: #fff;
        border-radius: 4px;
        margin: 32px auto 0 auto;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);

        display: grid;
        grid-template-areas:
          "."
          "title"
          "desc";
        grid-template-rows: auto max-content max-content;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;

        &:hover {
          cursor: pointer;
        }

        h4 {
          grid-area: title;
          margin: 0;
          font-size: 36px;
          font-weight: bold;
          max-width: 70%;
        }
        p {
          grid-area: desc;
          margin: 0;
          font-size: 24px;
          max-width: 70%;
        }
      }
    }
  }

  .extraInfo {
    padding: 0 40px;
    margin-top: 48px;

    p {
      table {
        width: 100%;
        max-width: 340px;
        margin-top: 32px;
        margin-bottom: 38px;

        tbody {
          tr{
            td{
              &:nth-child(even) {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .ginconf {
      .cont {
        grid-template-columns: 1fr;

        .btn {
          height: 200px;
          padding: 20px;
          h4 {
            font-size: 28px;
            max-width: 100%;
          }
          p {
            font-size: 20px;
            max-width: 100%;
          }
        }
      }
    }
    .extraInfo {
      p {
        table{
          max-width: 100%;
        }
      }
    }
  }
}
