.video_container {
  max-width: 1470px;

  display: grid;
  grid-template-areas: "prv list nxt";
  grid-template-columns: 25px minmax(auto, max-content) 25px;
  place-items: center;
  position: relative;
  overflow: hidden;
 
  .videoBtn {
    max-width: 75px;
    max-height: 75px;
    z-index: 9;
  }
  .previousBtn {
    grid-area: prv;
    position: absolute;
    left: -4px;

    img {
      transform: rotate(180deg);
    }
  }
  .nextBtn {
    grid-area: nxt;
    position: absolute;
    right: -4px;
  }

  ul {
    grid-area: list;
    padding: 4px 0;
    margin-bottom: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 340px));
    column-gap: 1em;
  }
}

@media screen and (max-width: 1340px) {
  .video_container {
    ul {
      grid-template-columns: repeat(3, minmax(300px, 340px));
    }
  }
}
@media screen and (max-width: 1024px) {
  .video_container {
    ul {
      grid-template-columns: repeat(2, minmax(300px, 340px));
    }
  }
}
@media screen and (max-width: 705px) {
  .video_container {
    .videoBtn {
      transform: scale(0.9);
    }
    ul {
      grid-template-columns: minmax(300px, 340px);
    }
  }
}
