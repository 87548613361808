.mangdconf {
  margin: 16px auto 0 auto;
  display: grid;
  grid-template-areas:
    "name btns"
    "line line";
  grid-template-columns: minmax(auto, 60%) auto;
  max-width: 1200px;

  .name {
    grid-area: name;
    display: flex;

    h5 {
      line-height: 36px;
      max-width: 125px;
      font-size: 18px;
      margin: 0;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span {
      font-size: 16px;
      line-height: 36px;
      margin: 0 0 0 8px;
    }
  }

  .btn_group {
    grid-area: btns;
    display: grid;
    max-width: 20em;
    width: 100%;
    grid-template-areas: "decrement line value line2 increment";
    grid-template-columns: auto 4px auto 4px auto;
    grid-template-rows: 36px;
    place-items: center;

    border-radius: 4px;
    background: linear-gradient(0deg, #e03131, #fab005, var(--clr-lime-8));
    background-size: 100000% 100000%;
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    margin: 0 0 0 auto;

    p {
      display: grid;
      place-items: center;
      grid-area: value;
      margin: 0;
      max-width: 70px;
      min-width: 45px;
    }
    .line_one {
      grid-area: line;
    }
    .line_two {
      grid-area: line2;
    }
    .line {
      height: 70%;
      width: 2px;
      background-color: #fff;
    }
    .increment {
      grid-area: increment;
    }
    .decrement {
      grid-area: decrement;
    }
    button {
      width: 100%;
    }
    button:disabled,
    button[disabled] {
      cursor: unset;
      img {
        opacity: 0.3;
      }
    }
  }

  .separator {
    grid-area: line;
    height: 1px;
    width: 100%;
    background-color: rgb(160, 160, 160);
    margin-top: 16px;
  }
}
