.title {
  color: var(--light-text-clr-0);
  font-size: 52px;
  font-weight: 800;
  padding: 64px 20px;
  text-align: center;
}

.shopping_container {
  display: grid;
  grid-template-columns: 62% auto;
  grid-template-areas:
    "products smts"
    "products rabatt"
    "products .";
  column-gap: 4em;
  margin-top: 32px;

  .product {
    grid-area: products;
    width: 100%;

    .econtainer {
      display: grid;
      grid-template-columns: auto/*  40px */;
      grid-template-areas: ".";

      img {
        /* grid-area: btn; */
        padding: 4px;
        margin-left: 12px;
        margin-top: 16px;
        cursor: pointer;
      }

      details {
        margin-bottom: 32px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        summary {
          display: flex;
          margin: 0 0 0 auto;
          background-color: var(--clr-cyan-5);
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          border-radius: 4px 4px 0px 0px;
          padding: 12px 18px 12px 24px;

          p {
            margin: 0;
          }

          .price {
            margin: 0 0 0 auto;
          }

          &::after {
            content: url(../assets/down_arrow_white.svg);
            margin-left: 8px;
            transform: rotate(180deg);
          }
        }

        .listcontainer {
          display: grid;
          grid-template-columns: 40% auto;
          grid-template-areas:
            "hl pt"
            "hl txt";
          /* column-gap: 2em; */

          .herblist {
            grid-area: hl;
            padding: 12px 16px;
            margin: 0;

            .bold {
              font-weight: bold;
            }

            li {
              display: flex;
              justify-content: space-between;
              border-bottom: 2px solid #adb5bd;

              p {
                margin: 0;
                padding: 0 2px;
              }
            }
          }

          .price_table {
            grid-area: pt;
            padding: 12px 16px;
            table {
              margin: 0;
              border-collapse: collapse;
              height: max-content;
              width: 100%;
              tr {
                border-bottom: 2px solid #adb5bd;

                th {
                  padding: 0 8px;
                  font-weight: bold;
                  &:last-child {
                    text-align: end;
                  }
                }
                td {
                  padding: 0 8px;
                  width: max-content;
                  &:nth-child(2) {
                    text-align: center;
                  }
                  &:last-child {
                    text-align: end;
                  }
                }

                &:last-child {
                  border-bottom: none;
                  font-weight: bold;
                }
              }
            }
          }

          .txt {
            grid-area: txt;
            padding: 12px 24px;
          }
        }
      }

      details[open] summary:after {
        transform: rotate(0deg);
      }
    }
  }

  .smts {
    grid-area: smts;
    background-color: var(--clr-cyan-0);
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: auto;

    h2 {
      border-radius: 4px 4px 0 0;
      padding: 16px 32px;
      background-color: var(--clr-cyan-5);
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }
    li {
      padding: 0 32px;
      list-style: none;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
        font-size: 18px;
      }
      .bold {
        font-weight: bold;
      }
      .big {
        font-size: 22px;
      }
      .small {
        font-size: 14px;
      }
    }
    .total {
      margin-top: 16px;
    }
    .frakt {
      margin-top: 8px;
    }
    .sum {
      margin-top: 16px;
    }
    .moms {
      margin-top: 0;
    }
    .sammanbtn {
      max-width: 333px;
      margin: 48px auto 16px auto;
      text-align: center;
      background-color: #e8590c;
      border-radius: 4px;
      font-weight: bold;
      font-size: 20px;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 12px 24px;
        color: #fff;
        text-decoration: none;
      }
    }
    .disabled {
      pointer-events: none;
      background-color: gray;
    }
    .separate {
      width: 95%;
      height: 2px;
      background-color: var(--clr-gray-5);
      margin: 0 auto;
    }
  }

  form {
    grid-area: rabatt;
    max-width: 600px;
    display: grid;
    grid-template-columns: 70% auto;
    column-gap: 8px;
    padding: 20px;
    margin-top: 48px;
    background-color: var(--clr-cyan-0);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    input {
      border-radius: 4px;
      height: 52px;
      text-indent: 8px;
    }
    input[type="text"] {
      font-size: 18px;
      border: 1px solid #9c9c9c;
    }
    input[type="submit"] {
      background-color: var(--clr-cyan-6);
      border: none;
      font-weight: bold;
      font-size: 20px;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 1220px) {
  .shopping_container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      "products"
      "smts"
      "rabatt";

    .product {
      .econtainer {
        details {
          .listcontainer {
            grid-template-columns: 100%;
            grid-template-areas:
              "pt"
              "hl";
          }
        }
      }
    }
  }
}
