:root {
  --accent: #e4572e;
  --accent-darker: #db4b1f;

  --light-text-clr-0: #fff;
  --light-text-clr-1: #bebebe;

  --dark-text-clr-0: #000;
  --dark-text-clr-1: #333;

  --clr-cyan-0: #F1FAFB;
  --clr-cyan-1: #DAEFF1;
  --clr-cyan-2: #B8DCE2;
  --clr-cyan-3: #91C8D2;
  --clr-cyan-4: #6DB7C3;
  --clr-cyan-5: #52ABB9;
  --clr-cyan-6: #469FAE;
  --clr-cyan-7: #348E9F;
  --clr-cyan-8: #0C8599;
  --clr-cyan-9: #1E7D8F;

  --clr-gray-0: #f8f9fa;
  --clr-gray-1: #f1f3f5;
  --clr-gray-2: #e9ecef;
  --clr-gray-3: #dee2e6;
  --clr-gray-4: #ced4da;
  --clr-gray-5: #adb5bd;
  --clr-gray-6: #868e96;
  --clr-gray-7: #495057;
  --clr-gray-8: #343a40;
  --clr-gray-9: #212529;

  --clr-lime-0: #f4fce3;
  --clr-lime-1: #e9fac8;
  --clr-lime-2: #d8f5a2;
  --clr-lime-3: #c0eb75;
  --clr-lime-4: #a9e34b;
  --clr-lime-5: #94d82d;
  --clr-lime-6: #82c91e;
  --clr-lime-7: #74b816;
  --clr-lime-8: #66a80f;
  --clr-lime-9: #5c940d;

  --clr-error: #e03131;
  --clr-warning: #fab005;
  --clr-success: #66a80f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: var(--bg);
  font-family: "Nunito";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (max-width: 768px) {
  html,
  body {
    overflow: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.customBtn {
  background-color: #7092be;
  padding: 4px 32px;
  margin: 28px 16px 0 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
}

.customBtn2 {
  background-color: #526472;
  padding: 4px 10px 4px 10px;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  border-radius: 4px;

  img {
    max-width: 16px;
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.restartBtn {
  margin: 28px 8px 0 0;
}
.resetBtn {
  margin: 28px 0 0 0;
}

@media only screen and (max-width: 768px) {
  .customBtn2 {
    font-size: 16px;
  }
  .resetBtn {
    float: right;
  }
}

/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./assets/fonts/nunito-v16-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/nunito-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("./assets/fonts/nunito-v16-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/nunito-v16-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("./assets/fonts/nunito-v16-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/nunito-v16-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-800 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("./assets/fonts/nunito-v16-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/nunito-v16-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
