.card {
  margin: 8px;
  display: grid;
  border-radius: 4px;
  transition: all 400ms ease;
  min-height: 220px;

  .content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .image {
      position: absolute;
      overflow: hidden;
      background-color: #666;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 4px;
      filter: saturate(0);
      width: 100%;
      height: 100%;

      transition: transform 0.175s ease-in-out;
    }

    .overlay {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, #00000088 30%, #ffffff44 100%);
    }

    .text {
      padding: 16px;
      position: relative;
      z-index: 2;
      height: 100%;
      text-align: start;

      display: grid;
      grid-template-areas:
        "."
        "title"
        "link";
      grid-template-rows: auto max-content max-content;

      h2 {
        grid-area: title;
        font-weight: bold;
        color: #fff;
        max-width: 16ch;
      }
      p {
        grid-area: link;
        font-size: 16px;
        font-weight: bold;
        color: #f2994a;
        margin: 0;
        
        img {
          margin-left: 4px;
          margin-bottom: 1px;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    .content {
      .image {
        filter: saturate(100%);
        transform: scale(1.025);
      }
    }
  }
}
