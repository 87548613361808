.btn_group {
  display: grid;
  grid-template-areas: "decrement line value line2 increment";
  grid-template-rows: 36px;
  place-items: center;
  width: 160px;

  border-radius: 4px;
  background-color: var(--clr-cyan-4);
  color: #fff;
  font-size: 20px;
  margin-left: 14px;

  p {
    display: grid;
    place-items: center;
    grid-area: value;
    margin: 0;
  }
  .line_one {
    grid-area: line;
  }
  .line_two {
    grid-area: line2;
  }
  .line {
    height: 70%;
    width: 1px;
    background-color: #fff;
  }
  .increment {
    grid-area: increment;
  }
  .decrement {
    grid-area: decrement;
  }
  button {
    width: 100%;
  }
  button:disabled,
  button[disabled] {
    cursor: unset;
    img {
      opacity: 0.3;
    }
  }

  @media only screen and (max-width: 620px) {
    grid-template-rows: 28px;
  }
}
