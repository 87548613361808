.title {
  color: var(--light-text-clr-0);
  font-size: 52px;
  font-weight: 800;
  padding: 64px 20px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 42px;
  }
}
@media only screen and (max-width: 450px) {
  .title {
    font-size: 28px;
  }
}

.shop_nav {
  max-width: 1440px;
  padding: 16px 20px;
  margin: 0 auto;
  position: relative;
  top: -32px;
  height: 64px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  z-index: 2;

  .nav_group {
    button {
      padding: 4px 16px;
      padding-right: 96px;
      color: var(--clr-gray-9);
      font-size: 20px;
      font-weight: bold;
      background-color: var(--clr-cyan-1);
      margin-right: 16px;
      border-radius: 4px;
    }
  }

  .cart_group {
    display: flex;
    p {
      margin-right: 14px;
    }

    .cart_icon {
      border: 2px solid #9f9f9f;
      border-radius: 4px;
      padding: 8px;
      position: relative;
      /* margin-left: 14px; */
      /* max-height: 40px;
      max-width: 40px; */

      span {
        position: absolute;
        background-color: #e4572e;
        border-radius: 50%;
        padding: 0 3px;
        color: white;
        font-weight: bold;

        display: grid;
        place-items: center;

        position: absolute;
        top: -12px;
        right: -12px;

        p {
          margin: 0;
          width: 2ch;
        }
      }

      .cart {
        visibility: hidden;
        opacity: 0;

        border-radius: 4px;
        border: 1px solid #999999;
        background-color: white;

        min-width: 400px;
        min-height: 250px;

        position: absolute;
        right: -2px;
        margin-top: 12px;

        transition: all 300ms ease;
        cursor: default;

        .cart_btns {
          padding: 20px 0;
          font-weight: bold;
          text-align: center;
          background-color: #efefef;
          min-width: 100%;

          position: absolute;
          bottom: 0;

          mark {
            font-weight: 600;
            color: #3170b9;
            background-color: transparent;
          }
          button {
            color: #fff;
            cursor: pointer;
            font-weight: bold;
            padding: 6px 16px;
            border-radius: 4px;
            text-transform: uppercase;
            background-color: #31b947;
          }
        }

        &:hover {
          visibility: visible;
          opacity: 1;
        }
      }

      &:hover {
        .cart {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .cart_icon:hover {
      .cart {
        display: block;
      }
    }
  }
}

/* .contents {
  margin-bottom: 200px;
}
 */