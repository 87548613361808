.main_heading {
  background-color: var(--clr-cyan-5);
  width: 100%;
  border-radius: 4px 4px 0 0;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0 14px;
  height: 60px;
  display: grid;
  place-items: center start;
  color: #fff;
}

.bottleconfig_container {
  table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    thead {
      background-color: var(--clr-cyan-1);
      tr {
        th {
          font-size: 24px;
          font-weight: normal;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 20px;
        }
      }
    }

    th,
    td {
      padding: 0 14px;
    }
    td {
      margin-top: 12px;
    }
  }

  .bottle_cont {
    .bottle_head {
      ul {
        background-color: var(--clr-cyan-1);
        list-style-type: none;
        margin: 0;
        padding: 0 14px;

        display: flex;
        justify-content: space-between;
        li {
          font-size: 24px;
          font-weight: normal;
          width: 100%;
          max-width: 200px;
          &:nth-child(2) {
            text-align: end;
          }
          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
    .bottle_body {
      ul {
        list-style-type: none;
        padding: 0 14px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid gray;
        li {
          font-size: 20px;
          width: 100%;
          max-width: 200px;
          margin: 8px 0;
          &:first-child {
            max-width: 300px;
          }
          &:nth-child(2) {
            text-align: end;
            max-width: 100px;
          }
          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 620px) {
    .bottle_cont {
      .bottle_head {
        ul {
          li {
            max-width: 100%;
            font-size: 18px;
            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(3) {
              text-align: start;
            }
          }
        }
      }
      .bottle_body {
        ul {
          display: grid;
          grid-template-areas:
            "btn name"
            ". price";
          grid-template-columns: 40% 60%;
          margin-top: 8px;
          li {
            max-width: 100%;
            font-size: 16px;
            margin: 0;
            &:nth-child(1) {
              grid-area: name;
              text-align: start;
            }
            &:nth-child(2) {
              grid-area: price;
              text-align: start;
            }
            &:nth-child(3) {
              grid-area: btn;
              width: 100px;
              height: 28px;
            }
          }
        }
      }
    }
  }
}
