.max_width {
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 750px) {
  .max_width {
    padding: 0;
  }

  .navextra {
    padding: 0 20px;
  }
}
