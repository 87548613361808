.product_container {
  max-width: 100%;
  font-size: 18px;

  display: grid;
  grid-template-columns: min-content auto max-content max-content;
  place-items: center;
  margin-bottom: 8px;
  padding: 8px;

  img {
    max-width: 30px;
    max-height: 30px;
    margin-right: 8px;
    text-align: left;
    cursor: pointer;
  }
  .name {
    width: 100%;
    font-weight: 600;
    margin: 0;
  }
  .price {
    margin: 0 16px;
  }
  .btn_group {
    background-color: var(--clr-cyan-5);
    border-radius: 4px;

    display: flex;
    place-items: center;
    .btn {
      display: flex;
      place-items: center;
      color: #fff;
      margin: 4px 2px;
      padding: 0px 16px;
      height: 30px;
      font-weight: bold;
      font-size: 24px;
    }
    .btn_minus {
      border-right: 2px solid #fff;
    }
    .btn_plus {
      border-left: 2px solid #fff;
    }
    .btn_primary {
      cursor: unset;
      font-size: 18px;
      padding: 0 18px;
    }
  }
}
