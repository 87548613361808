.container {
  color: var(--dark-text-clr-1);
  background-color: white;
  margin-bottom: 150px;
  margin-top: 200px;
  z-index: 999;
  position: absolute;
  left: 0;
  right: 0;

  display: grid;
  grid-template-areas:
    "info"
    "contact";
  column-gap: 4em;
  padding: 0 20px;

  .info {
    grid-area: info;
    max-width: 600px;
    margin: 0 auto;
    display: grid;
    grid-template-areas:
      "."
      "title"
      "desc";
    grid-template-rows: auto max-content max-content;

    h1 {
      grid-area: title;
    }
    .footerbeskrivning {
      grid-area: desc;
      margin: 1em 0;
      font-size: 18px;

      p {
        margin: 8px 0;
      }
      a {
        font-size: 16px;
      }
    }
  }

  .contact {
    grid-area: contact;
    width: 600px;
    padding: 0;
    margin: 0 auto;
    list-style: none;

    li {
      display: grid;
      grid-template-columns: max-content auto;
      column-gap: 2em;
      margin: 32px 0;

      img {
        width: 32px;
      }

      p {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .container {
    .info {
      text-align: center;
    }
    .contact {
      width: auto;
    }
  }
}
