.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  padding: 1.5em 1em;
  z-index: 999999;

  display: grid;
  place-items: center;

  .content {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;

    display: grid;
    grid-template-areas:
      "x     .     ."
      "video video ov";
    grid-template-columns: auto auto max-content;
    column-gap: 64px;

    span {
      grid-area: x;
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      cursor: pointer;
      text-align: start;
    }

    .video {
      grid-area: video;
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      @media screen and (orientation: landscape) and (max-device-width: 1200px) {
        iframe {
          height: 80%;
        }
      }
    }

    .ov {
      grid-area: ov;
      border-radius: 4px;
      width: 340px;
      height: max-content;
    }

    @media screen and (max-width: 1024px) {
      grid-template-areas:
        "x"
        "video"
        "ov";

      grid-template-columns: auto;

      .ov {
        margin: 0 auto;
      }
    }
  }
}
