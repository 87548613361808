.herbList {
  label {
    .name {
      p {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;

        margin: 1em;
        padding: 1em;

        display: none;
        font-size: 18px;
        font-weight: 600;
        max-width: 1200px;
        color: var(--light-text-clr-0);
        border: 1.5px solid #000;
        background-color: #748940;

        overflow: hidden;
        box-shadow: -1px 1px 9px 0px rgba(0, 0, 0, 0.29);
        -webkit-box-shadow: -1px 1px 9px 0px rgba(0, 0, 0, 0.29);
        -moz-box-shadow: -1px 1px 9px 0px rgba(0, 0, 0, 0.29);
      }
      &:hover {
        p {
          display: block;
        }
      }
    }
  }
}

.shop_nav {
  max-width: 1440px;
  padding: 16px 20px 0 20px;
  margin: 0 auto;
  position: relative;
  top: -32px;
  height: max-content;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  z-index: 2;

  .nav_group {
    button {
      padding: 4px 16px;
      padding-right: 96px;
      color: var(--clr-gray-9);
      font-size: 20px;
      font-weight: bold;
      background-color: var(--clr-cyan-1);
      margin-right: 16px;
      border-radius: 4px;
    }
  }

  .cart_group {
    display: flex;
    position: relative;

    p {
      margin-right: 14px;
    }

    .cart_name {
      display: grid;
      place-items: center;
      margin-bottom: 0;
    }

    .cart_icon {
      border: 2px solid #9f9f9f;
      border-radius: 4px;
      padding: 4px 6px;
      height: max-content;

      span {
        position: absolute;
        background-color: #e4572e;
        border-radius: 50%;
        padding: 0 3px;
        color: white;
        font-weight: bold;

        display: grid;
        place-items: center;

        position: absolute;
        top: -12px;
        right: -12px;

        p {
          margin: 0;
          width: 2ch;
          display: grid;
          place-items: center;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .cart {
      border-radius: 4px;

      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      background-color: white;

      min-width: 340px;

      position: absolute;
      right: -19px;
      margin-top: 45px;

      transition: all 300ms ease;
      cursor: default;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          .cart_item {
            padding: 16px;
            opacity: 0.8;
            display: grid;
            grid-template-areas:
              "img name"
              "img price";
            grid-template-columns: max-content auto;
            column-gap: 8px;

            img {
              grid-area: img;
              background-color: #3170b9;
              width: 60px;
              height: 60px;
            }
            p {
              margin: 0;
              color: #000;
              font-weight: 600;
            }

            .item_name {
              grid-area: name;
            }
            .item_cost {
              grid-area: price;
            }
          }

          .separator {
            grid-area: sep;
            height: 1px;
            /* background-color: #555555; */
            width: 100%;
          }
        }
      }

      .cart_btns {
        padding: 20px 0;
        font-weight: bold;
        text-align: center;
        background-color: var(--clr-cyan-0);
        min-width: 100%;

        position: relative;
        bottom: 0;

        mark {
          font-weight: 600;
          color: #3170b9;
          background-color: transparent;
        }
        a {
          color: #fff;
          cursor: pointer;
          font-weight: bold;
          padding: 6px 16px;
          border-radius: 4px;
          text-transform: uppercase;
          background-color: var(--accent);
        }
      }
    }
  }
}

.styledtabs {
  display: none;
}

.main_container {
  max-width: 100%;
  margin-top: 24px;

  display: grid;
  grid-template-areas:
    "title title"
    "desc desc"
    "box overview"
    ". btns";
  grid-template-columns: 74% auto;
  column-gap: 2em;

  .ctitle {
    grid-area: title;
    font-size: 42px;
    font-weight: bold;
  }
  .cdesc {
    grid-area: desc;
    font-size: 22px;
  }
  .box {
    grid-area: box;
    overflow: hidden;
  }
  .overview {
    grid-area: overview;

    .overview_box {
      max-width: 100%;
      min-height: 500px;
      background-color: #fff;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      height: 100%;

      ul {
        padding: 8px;
        margin: 0;
        list-style: none;
        overflow: hidden;

        li {
          display: flex;
          justify-content: space-between;
          p {
            margin: 0;
          }
        }

        .separator {
          height: 1px;
          width: 100%;
          padding: 1px 0;
          background-color: var(--clr-gray-5);
        }
      }
    }
    .overview_header {
      background-color: var(--clr-cyan-5);
      padding: 4px 0;
      border-radius: 4px 4px 0 0;
      grid-area: title;
      h4 {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        padding: 12px;
        margin: 0;
      }
    }
  }

  .container_btn_group {
    grid-area: btns;
    display: grid;
    place-items: center;

    button {
      border-radius: 4px;
      border: 1px solid #15aabf;
      background-color: var(--clr-cyan-0);
      font-weight: bold;
      font-size: 18px;
      margin: 16px 0 0 0;
      padding: 4px 0;
      width: 90%;
    }
  }
}

.bottle_conf_container {
  max-width: 100%;
  margin-top: 24px;

  display: grid;
  grid-template-areas:
    "title title"
    "desc desc"
    "box overview";
  grid-template-columns: 70% auto;
  column-gap: 2em;
}

.container {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-height: 580px;

  .heading {
    background-color: var(--clr-cyan-5);
    color: #fff;
    padding: 16px;
    border-radius: 4px 4px 0 0;
    font-weight: 700;
    margin: 0;
  }

  .container_btn_group_main {
    width: 160px;
    margin: 0 0 24px auto;
    button {
      border-radius: 4px;
      border: 1px solid #15aabf;
      background-color: var(--clr-cyan-0);
      font-weight: bold;
      font-size: 16px;
      margin: 16px 0 0 0;
      padding: 4px 0;
      width: 90%;
    }
  }
}

.wizard_container {
  width: 1440px;
  max-width: calc(100% - 40px);
  position: absolute;
  display: inline-block;
  height: calc(100vh - 84px);
  overflow: hidden;

  scroll-snap-type: both mandatory;

  @media screen and (max-width: 750px) {
    max-width: calc(100%);
  }

  @media screen and (orientation: landscape) and (min-device-width: 200px) and (max-device-width: 1000px) {
    max-width: calc(100%);
    height: 100%;
  }
}

.recipes_container {
  h4 {
    font-size: 24px;
    color: #fff;
    padding: 16px 16px;
    font-weight: 700;
    background-color: var(--clr-cyan-5);
  }
}

.botanicals {
  padding: 8px 16px;
}

.container_btn_group {
  grid-area: btns;
  display: grid;
  place-items: center;

  button {
    border-radius: 4px;
    border: 1px solid #15aabf;
    background-color: var(--clr-cyan-0);
    font-weight: bold;
    font-size: 18px;
    margin: 16px 0 0 0;
    padding: 4px 0;
    width: 90%;
  }
}

.wizardtwo {
  max-width: 1440px;
  margin: 64px auto 0 auto;

  .stepone {
    font-size: 18px;
    color: #000;

    display: grid;
    grid-template-areas:
      "img"
      ".";
    place-items: center;
    text-decoration: none;
    margin-bottom: 48px;
    position: relative;

    h4 {
      margin: 0;
      margin-top: 8px;
      z-index: 99;
    }

    img {
      grid-area: img;
      max-height: 64px;
      max-width: 64px;
      transform: scale(1.3);
      content: url("../assets/icons/tribtn.svg");
    }
  }

  .reversed {
    font-size: 18px;
    color: #000;

    display: grid;
    grid-template-areas:
      "."
      "img";
    place-items: center;
    text-decoration: none;
    margin-bottom: 48px;
    position: relative;

    h4 {
      margin: 0;
      margin-bottom: 8px;
      z-index: 99;
    }

    img {
      grid-area: img;
      max-height: 64px;
      max-width: 64px;
      transform: rotate(180deg) scale(1.3);
      content: url("../assets/icons/tribtn.svg");
    }
  }
}

.wizardtyp {
  display: grid;
  grid-template-columns: 70% auto;
  column-gap: 2em;

  .typ_container {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    margin: 0 auto 64px auto;
    width: 100%;
  }

  .overview {
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (max-width: 1020px) {
  .main_container {
    grid-template-areas:
      "title"
      "desc"
      "box"
      "overview";
    grid-template-columns: 100%;

    .overview {
      margin-top: 32px;
    }
  }

  .wizardtyp {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    .typ_container {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 750px) {
  .shop_nav {
    .nav_group {
      button {
        padding-right: 32px;
        font-size: 18px;
      }
    }

    .cart_group {
      .cart_name {
        display: none;
      }
    }
  }
  .container {
    width: 100%;
    min-height: 400px;
  }
}
