.recipe_container {
  display: grid;
  grid-template-areas:
    "title title title"
    "search search search"
    "nxtBtn list prvBtn";
  grid-template-columns: 48px auto 48px;
  margin: 0 auto 0 0;
  width: 100%;
  border: 4px;

  .title {
    grid-area: title;
    margin: 10px 16px 10px 12px;
    font-size: 24px;
    font-weight: bold;
    color: var(--clr-gray-9);
    border: 1px solid var(--clr-cyan-5);
    background-color: var(--clr-cyan-0);
    padding: 0 0 0 4px;
  }

  .prvBtn {
    grid-area: nxtBtn;
    padding: 0 8px;
  }

  .nxtBtn {
    grid-area: prvBtn;
    padding: 0 8px;
  }

  .search_input {
    grid-area: search;
    background-image: url("../../assets/icons/search2.png");
    background-position: 8px 8px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    max-width: 175px;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 8px 2px 34px;
    color: var(--clr-gray-6);
    background-color: var(--clr-gray-0);
    border: 1px solid var(--clr-cyan-5);
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    float: right;
    height: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--clr-gray-6);
    }
  }

  .recipe_ul {
    grid-area: list;
    padding: 4px 8px;
    margin-bottom: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(4, minmax(240px, 1fr));

    li {
      display: grid;
      text-align: left;
      overflow: visible;
      margin: 4px 6px;
      max-width: 260px;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-radius: 4px;
      padding-top: 7px;
      padding-bottom: 5px;
      padding-left: 38px;
      padding-right: 6px;
      max-height: 39px;
      font-weight: bold;
      background-color: var(--clr-cyan-0);

      label {
        font-size: 18px;
        cursor: pointer;
        margin-bottom: 0;
        color: var(--dark-text-clr-0);
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      span {
        position: absolute;
        top: 7px;
        left: 6px;
        height: 25px;
        width: 25px;
        background-color: var(--clr-cyan-2);
        border-radius: 4px;

        &::after {
          content: "";
          position: absolute;
          display: none;
        }

        &:hover {
          background-color: var(--clr-cyan-3);
        }
      }
      input:checked ~ span {
        background-color: var(--clr-cyan-2);
      }
      input:disabled ~ span {
        background-color: #666;
      }
      input:checked ~ span::after {
        display: block;
      }

      :after {
        left: 9px;
        top: 4px;
        width: 7px;
        height: 14px;
        border: solid var(--clr-cyan-4);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .recipe_info {
        font-size: 18px;
        position: relative;
        display: flex;
        justify-content: space-between;
        img {
          position: absolute;
          top: -3px;
          right: 0;
          cursor: pointer;
          content: url("../../assets/icons/playIcon.svg");

          &:hover {
            content: url("../../assets/icons/playIconDark.svg");
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .recipe_container {
    .recipe_ul {
      grid-template-columns: repeat(3, minmax(200px, 1fr));
    }
  }
}
@media screen and (max-width: 825px) {
  .recipe_container {
    .title {
      font-size: 20px;
    }
    .recipe_ul {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
  }
}
@media screen and (max-width: 680px) {
  .recipe_container {
    .prvBtn {
      padding: 0 8px 0 12px;
    }
    .nxtBtn {
      padding: 0 12px 0 8px;
    }
    .recipe_ul {
      padding: 4px;
      grid-template-columns: 1fr;

      li {
        margin: 0 auto;
        min-width: 240px;
      }
    }
  }
}
