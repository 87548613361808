.parent {
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;

  .child {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    height: 100vh;
  }

  .popup_button {
    padding: 8px 32px;
    font-size: 18px;
    color: var(--light-text-clr-0);
    background-color: var(--accent);
    border-radius: 4px;
    margin-top: 32px;
  }
}

.home_container {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto 64px auto;

  display: grid;
  grid-template-areas:
    "bottle ."
    "text  text"
    "cards cards";
  grid-template-columns: 48% auto;
  grid-template-rows: 340px max-content 50%;

  .special {
    display: grid;
    grid-template-columns: auto 30%;
    place-items: center;

    .offer {
      position: absolute;
      left: 63%;
      top: 4%;
      transform: translateX(-50%);

      border-radius: 50%;
      background-color: red;
      color: #fff;
      height: 300px;
      width: 300px;
      text-decoration: none;
      cursor: pointer;

      display: grid;
      grid-template-rows: auto 50%;
      place-items: center;
      justify-content: space-around;
      align-items: end;
      row-gap: 16px;

      h1,
      h2,
      h3,
      h4,
      p {
        max-width: 12ch;
        margin: 0;
      }

      p {
        font-size: 24px;
        margin-bottom: auto;
      }
    }
  }

  .bottle {
    grid-area: bottle;
    max-width: 340px;
    margin: 0 0 0 auto;
  }

  .text {
    grid-area: text;
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .card_container {
    grid-area: cards;
    min-height: 460px;
    width: 100%;
    max-width: 1200px;

    display: grid;
    grid-template-areas:
      "one three four"
      "two three five";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0 auto;

    animation: fadeInBottom 1.75s;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .home_container {
    .card_container {
      grid-template-areas:
        "three three"
        "one   two"
        "four  five";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 260px 260px 260px;
    }
  }
}

@media screen and (max-width: 660px) {
  .home_container {

    .special {
      .offer {
        left: 50%;
      }
    }
    .card_container {
      grid-template-areas:
        "three"
        "one"
        "two"
        "four"
        "five";
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
}
